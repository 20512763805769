<template>
	<div>
		<div class="father_box">
			<img src="https://official-huoshu.oss-cn-hangzhou.aliyuncs.com/static/pc/Doorboard.png" class="Doorboard" />
			<div class="introduce1">
				<div class="name">{{appDefault.title}}</div>
				<div class="value">{{appDefault.description}}</div>
			</div>
		</div>
		<div class="photo">
			<img src="https://official-huoshu.oss-cn-hangzhou.aliyuncs.com/static/pc/CompanyPhotos4.jpg" />
			<img src="https://official-huoshu.oss-cn-hangzhou.aliyuncs.com/static/pc/CompanyPhotos1.png" />
			<img src="https://official-huoshu.oss-cn-hangzhou.aliyuncs.com/static/pc/CompanyPhotos2.png" />
			<img src="https://official-huoshu.oss-cn-hangzhou.aliyuncs.com/static/pc/CompanyPhotos3.png" />
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
    props: {
      appDefault:{
        type: Object,
        default: () => {
          return {}
        }
      }
    },
		methods: {}
	}
</script>

<style scoped>
	.father_box {
		width: 43rem;
		margin: 3rem auto 0;
		position: relative;
	}

	.Doorboard {
		width: 21rem;
		/* height: 15.6rem; */
		position: relative;
		z-index: 1;
		border-radius: 0.6rem;
	}

	.introduce1 {
		width: 28.8rem;
		/* height: 16.8rem; */
		background: linear-gradient(153deg, rgba(253, 253, 253, 0.1) 0%, #FBFBFB 100%);
		position: relative;
		z-index: 3;
		padding: 2.31rem 1.56rem;
		float: right;
		margin-top: -10rem;
		border-radius: 0.9rem;
		backdrop-filter: blur(10px);
	}

	.introduce1>.name {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #333333;
		font-size: 1.32rem;
		font-weight: 500;
	}

	.value {
		color: #333333;
		font-size: 0.9rem;
		margin-top: 1.5rem;
		font-weight: 500;
		text-align: left;
	}

	.photo {
		width: 43rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		padding-top: 1.8rem;
		padding-bottom: 3rem;
	}

	.photo>img {
		width: 10rem;
		height: 6.2rem;
		border-radius: 0.42rem;
	}
</style>